<template>
  <v-app>
    <div class="container">
      
      <br>
      <br>
      <v-card
        class="mx-auto"
        max-width="1000"
      >
        <v-card-text>
          <div class="privacyNoticeText">
            <br>
            <br>
            <div data-custom-class="body">
              <strong>
                <span style="font-size: 26px;">
                  <span data-custom-class="title">
                    Cookie Policy
                  </span>
                </span>
              </strong>
            </div>
            <br>
            <div>
              <span style="color: rgb(127, 127, 127);">
              <strong>
                <span style="font-size: 15px;">
                <span data-custom-class="subtitle">
                  Last updated <span class="question">July 5, 2021</span>
                </span>
                </span>
              </strong>
              </span>
            </div>
            <br>
            <br>
            <br>
            <div style="line-height: 1.5;">
              <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span data-custom-class="body_text">
              <p> heedi ("we" or "us" or "our") may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website www.heedi.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site") to help customize the Site and improve your experience.</p>
              <p> We reserve the right to make changes to this Cookie Policy at any time and for any reason.  We will alert you about any changes by updating the "Last Updated" date of this Cookie Policy.  Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.</p>
              <p> You are encouraged to periodically review this Cookie Policy to stay informed of updates.  You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.</p>
              <p> This cookie policy was created using <a href="http://termly.io">Termly</a></p>
              </span>
              </span>
              
            </div>  
            <br>
            <br>
            <br>  

            <h2><span ref="#useOfCookies">USE OF COOKIES</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            A "cookie" is a string of information which assigns you a unique identifier that we store on your computer.  Your browser then provides that unique identifier to use each time you submit a query to the Site.  We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged in the Site, facilitate purchase procedures, and track the pages you visit.  Cookies help us understand how the Site is being used and improve your user experience.
            </span></span></p>
            <br>
            <br>

            <h2><span ref="#useOfCookies">TYPES OF COOKIES</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            The following types of cookies may be used when you visit the Site:
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Advertising Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you.  These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom.  These cookies are linked to a computer and do not gather any personal information about you.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Analytics Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site.  These cookies let us know what features on the Site are working the best and what features on the Site can be improved.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Our Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Our cookies are "first-party cookies", and can be either permanent or temporary.  These are necessary cookies, without which the Site won't work properly or be able to provide certain features and functionalities.  Some of these may be manually disabled in your browser, but may affect the functionality of the Site.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Personalization Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Personalization cookies are used to recognize repeat visitors to the Site.  We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Security Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Security cookies help identify and prevent security risks.  We use these cookies to authenticate users and protect user data from unauthorized parties.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Site Management Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page.  These cookies cannot be turned off individually, but you can disable all cookies in your browser.
            </span></span></p>

            <p><span style="font-size: 16px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <strong>Third-Party Cookies</strong>
            </span></span></p>

            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Third-party cookies may be placed on your computer when you visit the Site by companies that run certain services we offer.  These cookies allow the third parties to gather and track certain information about you.  These cookies can be manually disabled in your browser.
            </span></span></p>
            <br>
            <br>

            <h2><span ref="#useOfCookies">CONTROL OF COOKIES</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            You can change your cookie settings by going to <router-link to="/settings">Settings</router-link> in the upper-right main menu.
            </span></span></p>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            Most browsers are set to accept cookies by default.  However, you can remove or reject cookies in your browser's settings.  Please be aware that such action could affect the availability and functionality of the Site.
            </span></span></p>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            For more information on how to control cookies, check your browser or device's settings for how you can control or reject cookies, or visit the following links:
            </span></span></p>
            <br>
            <br>
            <ul>
              <li><a href="www.apple.com/safari">Apple Safari</a></li>
              <li><a href="www.google.com/chrome">Google Chrome</a></li>
              <li><a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a></li>
              <li><a href="https://www.microsoft.com/en-us/download/internet-explorer.aspx">Microsoft Internet Explorer</a></li>
              <li><a href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox</a></li>
              <li><a href="https://www.opera.com/">Opera</a></li>
              <li><a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_US&gl=US">Android (Chrome)</a></li>
              <li><a href="https://www.blackberry.com/us/en">Blackberry</a></li>
              <li><a href="https://apps.apple.com/us/app/google-chrome/id535886823">Iphone or Ipad (Chrome)</a></li>
              <li><a href="https://support.apple.com/guide/safari/see-the-webpages-open-on-your-other-devices-sfri40726/mac">Iphone or Ipad (Safari)</a></li>
            </ul>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            In addition, you may opt-out of some third-party cookies through the <a href="https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options">Network Advertising Initiative's Opt-Out Tool</a>
            </span></span></p>
            <br>
            <br>

            <h2><span ref="#useOfCookies">OTHER TRACKING TECHNOLOGIES</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience.  A "web beacon" or "pixel tag" is ting object or image embedded in the web page or email.  They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data.  They collect only a limited-set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside.  Web beacons and pixel tags cannot be declined.  However, you can limit their use by controlling the cookies that interact with them.
            </span></span></p>
            <br>
            <br>

            <h2><span ref="#useOfCookies">PRIVACY POLICY</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            For more information about how we use information collected by cookies and other tracking technologies, please refer to our <router-link to="/privacy">Privacy Policy</router-link> posted on the Site.  This Cookie Policy is part of and is incorporated into our Privacy Policy.  By using the Site, you agree to be bound by this Cookie Policy and Privacy Policy.
            </span></span></p>
            <br>
            <br>

            <h2><span ref="#useOfCookies">CONTACT US</span></h2>
            <br>
            <p><span style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            If you have questions or comments about this Cookie Policy, please contact us at:<br>
            <br>
            privacy@heedi.com
            </span></span></p>
            <br>
            <br>












          </div>

        </v-card-text>
      </v-card>
      <br>
      <br>

      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>
  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  // import imageModule from '@/store/image'
  import searchModule from '@/store/search'
  import settingsModule from '@/store/settings'
  import VueFooter from '@/components/VueFooter'

  import { mapActions } from 'vuex'


  

  export default {
    name: "CookiePolicy",
    order: 16,
    components: {
      VueFooter,

    },
    data () {
      return {
        overlay: false,
        showFooterBool: false,
        heroImageSrc: "",
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8'],


        list: [
          {
            name: "task 1",
            tasks: [
              {
                name: "task 2",
                tasks: []
              }
            ]
          },
          {
            name: "task 3",
            tasks: [
              {
                name: "task 4",
                tasks: []
              }
            ]
          },
          {
            name: "task 5",
            tasks: []
          }
        ],
      

        showBlock2: false,
        showBlock3: false,
        showBlock4: false,

        //vuedraggable variables
        enabled: true,
        dragging: false,
        /*
        list: [
          { name: "John", id: 0 },
          { name: "Joao", id: 1 },
          { name: "Jean", id: 2 }
        ],
        */
      }
    },
    computed: {
      
      /*
      elements: {
          get() {
            return this.$store.state.nested.elements;
          },
          set(value) {
            this.$store.commit("nested/updateElements", value, {root: true});
          }
        },
      */
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('image', [
        'getHeroImage'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon'
      ]),
      showFooter () {
        this.showFooterBool = true;
      },
      
      
      scrollToAnchorPoint(refName) {
        const el = this.$refs[refName]
        el.scrollIntoView({ behavior: 'smooth'})
      }
    },
    watch: {

    },
    async beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      //if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      //if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
  
    },
    created () {
      // gtag('config', 'G-H9RD8W4MLR', {'page_path': '/about'});
      this.autoSignIn()
      this.setShowSearchIcon(true)
    },
    mounted () {
    },
    updated () {

    }
  }
</script>

<style scoped>

  .privacyNoticeText {
    padding-left: 10px;
    padding-right: 10px;
  }

  .about {
    min-height: 400px;
  }

  .blockWrapper {
    background-color: white;
    padding: 30px;
    
    margin: 0 auto;
  }



  .searchContainer {
    text-align: center;

  }


  ul {
        list-style-type: square;
  }
  ul > li > ul {
    list-style-type: circle;
  }
  ul > li > ul > li > ul {
    list-style-type: square;
  }
  ol li {
    font-family: Arial ;
  }
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }


  .moreLink {
    display: block;
    width: 30%;
    max-width: 200px;
    border: 2px solid #999;
    padding: 3px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    color: #555;
    border-radius: 10px;
    background-color: #ddd;
    
  }
  .moreLink:hover {
    background-color: #000;
    color: white;
  }

  .downIcon {
    color: #777;
  }


  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .aboutContent {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
    font-weight: 400;
  }

  .searchContainer {
    text-align: center;
    font-size: .7em;

  }

  #heediSupportImg {
    width: 90%;
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .heediImgHolder {
    padding: 25px;
    text-align: center;
  }

  @media screen and (min-width: 400px) {

    #heediSupportImg {
      width: 240px;
    }

  }

  @media screen and (min-width: 510px) {


    .flexContainer {
      flex-direction: row;
      text-align: left;
    }
    .privacyNoticeText {
      padding-left: 40px;
      padding-right: 40px;
    }
  }


</style>